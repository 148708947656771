import styles from "./Section3.module.css";

const googleMapsEmbedLink =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2942.5057991087847!2d26.51299971163039!3d42.48079842734491!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a63434647e4f0d%3A0x1222581347bcf3f3!2z0YPQuy4g4oCe0JHQtdC70Lgg0JTRgNC40L3igJwgMTAsIDg2MDAg0LYu0LouINCY0LfRgtC-0LosINCv0LzQsdC-0Ls!5e0!3m2!1sbg!2sbg!4v1691323418896!5m2!1sbg!2sbg";

const Section3: React.FC = () => {
  return (
    <div className="section section3">
      <div className={styles.contactInfo}>
        <div className="contact-item">
          <p>Адрес: гр.Ямбол, Бели Дрин №10</p>
        </div>
        <div className="contact-item">
          <div>GSM: +359 883 354 449</div>
          <div>GSM: +359 899 271 495</div>
        </div>
        <div className="contact-item">
          <p>Email: office@geoworld.bg</p>
        </div>
      </div>
      <div className={styles.googleMapsContainer}>
        <iframe
          src={googleMapsEmbedLink}
          width="100%"
          height="300px"
          style={{ border: 0 }}
          allowFullScreen
          title="Google Maps"></iframe>
      </div>
    </div>
  );
};

export default Section3;
