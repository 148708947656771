import React from "react";
import "./App.css";

import Section1 from "./components/section1/Section1";
import Section2 from "./components/section2/Section2";
import Section3 from "./components/section3/Section3";
import Navbar from "./components/navbar/Navbar";

const App: React.FC = () => {
  return (
    <div className="App">
      <Navbar />
      <Section1 />
      <Section2 />
      <Section3 />
    </div>
  );
};

export default App;
