import backgroundImage from "./../../assets/images/2.jpg";
import styles from "./Section1.module.css";

const Section1: React.FC = () => {
  const sectionStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div className="section section1" style={sectionStyle}>
      <div className={styles.overlay}>
        <div className={styles.content}>
          <h2>Добре дошли в ГеоСвят МАП </h2>
          <p>
            Геосвят МАП ЕООД е новосъздадена фирма, изцяло от екип млади
            инженери с над 10 годишен опит в проектирането и изпълнението на
            инвестиционни проекти.
          </p>
          <p>
            Успяваме да участваме в проекти и да обърнем индивидуално внимание
            на своите клиенти като за целта разполагаме с професионална техника
            от последно поколение и специализиран софтуер, с който да изпълним
            качествено поставените ни задачи.
          </p>
          <p>
            Извършваме консултанстки услуги и се стремим да откликнем на нуждите
            на своитете клиенти.
          </p>
          <p>
            Нашият екип се състои от Дипломирани инженери с ППП (пълна
            проектантска правоспособност) по части:
          </p>
          <ul>
            <li>Геодезия</li>
            <li>Конструкции</li>
            <li>Транспортно строителство</li>
          </ul>
          <p>
            Геосвят МАП ЕООД е вписана в Регистъра на правоспособните лица към
            Агенция по геодезия, картография и кадастър на Република България
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section1;
