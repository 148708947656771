import styles from "./Section2.module.css";

const Section2: React.FC = () => {
  return (
    <div className={"section section2"}>
      <div className={styles.overlay}>
        <div className={styles.content}>
          <h2>Фирмата извършва дейности:</h2>
          <ul>
            <li>
              Геодезическо заснемане на обекти - имоти, сгради и съоръжения
            </li>
            <li>Трасиране на имоти, сгради и съоръжения</li>
            <li>Изготвяне на проекти за целите на Кадастъра</li>
            <li>Подготвяме проекти за изменение на кадастралната карта</li>
            <li>Изготвяне на Регулационни планове (ПУП)</li>
            <li>Вертикално планиране (ВП)</li>
            <li>Тахиметрични снимки</li>
            <li>Заснемане на земеделски земи </li>
            <li>Делби на имоти</li>
          </ul>
          <p>
            Отношението към нашите клиенти е коректно като едновременно с това
            залагаме на качествено и срочно изпълнение на проектите си.
            Проследяваме етапността на завършеност на проектите с цел улеснение
            на нашите Възложители.
          </p>
        </div>
      </div>
      {/* <div className={styles.additionalInfo}>test</div> */}
    </div>
  );
};

export default Section2;
