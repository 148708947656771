import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import logoImage from "./../../assets/images/logof.png";
import styles from "./Navbar.module.css";

const Navbar = () => {
  const [activeSection, setActiveSection] = useState<string>("section1");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  console.log(screenWidth);
  useEffect(() => {
    // Update screen width when the window is resized
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that the effect runs only once during component mount

  // Set the conditional offset value based on screen width
  const offsetValue = screenWidth < 500 ? -60 : -100;

  const handleSetActiveSection = (sectionId: string) => {
    setActiveSection(sectionId);
  };
  return (
    <nav className={styles.navbar}>
      <Link
        to="section1"
        spy={true}
        smooth={true}
        offset={offsetValue}
        duration={1000}
        activeClass={styles.active}
        onSetActive={() => handleSetActiveSection("section1")}>
        <div className={styles.logoContainer}>
          <img src={logoImage} alt="Company Logo" className={styles.logo} />
        </div>
      </Link>
      <Link
        to="section1"
        spy={true}
        smooth={true}
        offset={offsetValue}
        duration={1000}
        activeClass={styles.active}
        onSetActive={() => handleSetActiveSection("section1")}>
        <div className={styles.navText}>За нас</div>
      </Link>
      <Link
        to="section2"
        spy={true}
        smooth={true}
        offset={offsetValue}
        duration={1000}
        activeClass={styles.active}
        onSetActive={() => handleSetActiveSection("section2")}>
        <div className={styles.navText}>Услуги</div>
      </Link>
      <Link
        to="section3"
        spy={true}
        smooth={true}
        offset={offsetValue}
        duration={1000}
        activeClass={styles.active}
        onSetActive={() => handleSetActiveSection("section3")}>
        <div className={styles.navText}>Контакти</div>
      </Link>
    </nav>
  );
};

export default Navbar;
